
// Notification Messages
export const NOTIFICATION_SUCCESS = "Success"
export const NOTIFICATION_ERROR = "Error"
export const NOTIFICATION_WARNING = "Warning"

// Location Messages
export const LOCATION_CREATE_SUCCESS = "Location created successfully.";
export const LOCATION_UPDATE_SUCCESS = "Location updated successfully.";
export const LOCATION_DELETE_SUCCESS = "Location deleted successfully.";
export const LOCATION_DELETE_ERROR = "Failed to delete location.";
export const LOCATION_DELETE_ERROR_LAST_LOCATION = "There must be at least one location.";
export const LOCATION_DELETE_ERROR_KIOSK_CONNECTED = "This Location can not be deleted because it is currently in use by one of the Kiosks.";
export const LOCATION_SAVE_ERROR = "Failed to save location.";

// User Messages
export const USER_CREATE_SUCCESS = "User created successfully.";
export const USER_UPDATE_SUCCESS = "User updated successfully.";
export const USER_DELETE_SUCCESS = "User deleted successfully.";
export const USER_CREATE_ERROR_ALREADY_EXISTS = "User with this identity already exists in this organization.";
export const USER_DELETE_ERROR = "Failed to delete user.";
export const USER_DELETE_ERROR_DATE_ORDER = "End date cannot be before start date.";
export const USER_DELETE_ERROR_SUPER_ADMIN = (role: string) => `There must be at least one user with the ${role} role in your organization.`;
export const USER_DELETE_ERROR_SUPER_ADMIN_NOT_FOUND = 'Super Admin role not found.';
export const USER_SAVE_ERROR = "Failed to save user.";

// Role Messages
export const ROLE_CREATE_SUCCESS = "Role created successfully.";
export const ROLE_UPDATE_SUCCESS = "Role updated successfully.";
export const ROLE_DELETE_SUCCESS = "Role deleted successfully.";
export const ROLE_DELETE_ERROR = "Failed to delete role.";
export const ROLE_DELETE_ERROR_ONLY_ROLE = (userNames: string) => `This is the only role for user(s): ${userNames}. Please ensure all users are assigned to another role before deleting this role.`;
export const ROLE_SAVE_ERROR = "Failed to save role.";

// Agreement Messages
export const AGREEMENT_CREATE_SUCCESS = "Agreement created successfully.";
export const AGREEMENT_UPDATE_SUCCESS = "Agreement updated successfully.";
export const AGREEMENT_DELETE_SUCCESS = "Agreement deleted successfully.";
export const AGREEMENT_DELETE_ERROR = "Failed to delete agreement.";
export const AGREEMENT_SAVE_ERROR = "Failed to save agreement.";

// Red Flag Messages
export const RED_FLAG_UPDATE_SUCCESS = "Red flag updated successfully.";
export const RED_FLAG_LOG_ADD_SUCCESS = "Red flag log has been added successfully.";
export const RED_FLAG_SAVE_ERROR = "Failed to save red flag.";

// Field Messages
export const FIELD_CREATE_SUCCESS = "Field created successfully.";
export const FIELD_UPDATE_SUCCESS = "Field updated successfully.";
export const FIELD_DELETE_SUCCESS = "Field deleted successfully.";
export const FIELD_DELETE_ERROR = "Failed to delete field.";
export const FIELD_SAVE_ERROR = "Failed to save form field.";

// Invitations Messages
export const INVITATIONS_UPDATE_SUCCESS = "Invitation options updated successfully.";
export const INVITATIONS_SAVE_ERROR = "Failed to save invitations.";

// Confirmation Messages
export const CONFIRM_DELETE_TITLE = (title: string) => `Delete ${title}`;
export const CONFIRM_DELETE_CONTENT = (name: string) => `Are you sure you want to delete ${name}?`;
