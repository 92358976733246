import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { notification } from 'antd';

interface ModalFormProps {
    open: boolean;
    onOk: (data: any) => void;
    onClose: () => void;
}

const VisitorListModal: React.FC<ModalFormProps> = ({ open, onOk, onClose }) => {
    const [form] = OpForm.useForm();
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);
    return (
        <OpModal
            open={open}
            title={'Add Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.submit();
                        onClose();
                    })
                    .catch((errorInfo) => {
                        notification.error({
                            message: 'Validation Error',
                            description: 'Please fill in all required fields.',
                            placement: 'bottomRight',
                        });
                    });
            }}
            centered
        >
            <OpForm
                form={form}
                onSubmit={({ values }) => {
                    const trimmedValues = Object.fromEntries(
                        Object.entries(values).map(([key, value]) => [
                            key,
                            typeof value === 'string' ? value.trim() : value,
                        ])
                    );
                    onOk(trimmedValues);
                    form.resetFields();
                }}
                hasError={false}
                defaultButtons={false}
            >
                <OpForm.Input
                    label="First Name" name="firstName"
                    rules={[{ required: true, message: 'Please enter first name.' }]}
                />
                <OpForm.Input
                    label="Last Name" name="lastName"
                    rules={[{ required: true, message: 'Please enter last name.' }]}
                />
                {(invitationConfig?.email.included === 1) ? (
                    <OpForm.Input
                        label="Email" name="email"
                        rules={[
                            { required: invitationConfig.email.required === 1, message: 'Please enter email.' },
                            { type: 'email', message: 'Please enter a valid email address.' }
                        ]} />
                ) : undefined}
                {(invitationConfig?.visitPhone.included === 1) ? (
                    <OpForm.Input
                        label="Mobile Phone" name="mobilePhone"
                        rules={[{ required: invitationConfig.visitPhone.required === 1, message: 'Please enter mobile phone.' }]}
                    />
                ) : undefined}
            </OpForm>
        </OpModal>
    );
};

export default VisitorListModal;
