import React from 'react';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { notification } from 'antd';
import { VisitorWorkflowFields } from 'types/visitorWorkflowTypes';

interface ModalFormProps {
    open: boolean;
    isInviting: boolean;
    signInWorkflowFields?: VisitorWorkflowFields;
    visitor?: any | null;
    onOk: (data: any) => void;
    onClose: () => void;
}

const VisitorListModal: React.FC<ModalFormProps> = ({ open, isInviting, signInWorkflowFields, visitor, onOk, onClose }) => {
    const { invitationConfig } = useSelector((state: RootState) => state.visitorInvitation);

    const workflowFields = signInWorkflowFields;

    const [form] = OpForm.useForm();

    return (
        <OpModal
            open={open}
            title={visitor ? 'Edit Visitor' : 'Add Visitor'}
            okText="Save"
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.submit();
                        onClose();
                    })
                    .catch((errorInfo) => {
                        notification.error({
                            message: 'Validation Error',
                            description: 'Please fill in all required fields.',
                            placement: 'bottomRight',
                        });
                    });
            }}
            centered
        >
            <OpForm
                form={form}
                onSubmit={({ values }) => {
                    const trimmedValues = Object.fromEntries(
                        Object.entries(values).map(([key, value]) => [
                            key,
                            typeof value === 'string' ? value.trim() : value,
                        ])
                    );
                    onOk(trimmedValues);
                    form.resetFields();
                }}
                hasError={false}
                defaultButtons={false}
                initialValues={visitor ? visitor : undefined}
            >
                <OpForm.Input
                    label="First Name" name="firstName"
                    rules={[{ required: true, message: 'Please enter first name.' }]}
                />
                <OpForm.Input
                    label="Last Name" name="lastName"
                    rules={[{ required: true, message: 'Please enter last name.' }]}
                />
                {(!isInviting && workflowFields?.middleName.included === 1) && (
                    <OpForm.Input
                        label="Middle Name" name="middleName"
                        rules={[
                            {
                                required: (!isInviting && workflowFields.middleName.required === 1),
                                message: 'Please enter middle name.'
                            }
                        ]}
                    />
                )}
                {((isInviting && invitationConfig?.email.included === 1) || (!isInviting && workflowFields?.email.included === 1)) && (
                    <OpForm.Input
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: (isInviting && invitationConfig?.email.required === 1) ||
                                    (!isInviting && workflowFields?.email.required === 1),
                                message: 'Please enter email.'
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address.'
                            }
                        ]}
                    />
                )}
                {((isInviting && invitationConfig?.visitPhone.included === 1) || (!isInviting && workflowFields?.visitPhone.included === 1)) && (
                    <OpForm.Input
                        label="Mobile Phone" name="mobilePhone"
                        rules={[
                            {
                                required: (isInviting && invitationConfig?.visitPhone.required === 1) ||
                                    (!isInviting && workflowFields?.visitPhone.required === 1),
                                message: 'Please enter mobile phone.'
                            }
                        ]}
                    />
                )}
            </OpForm>
        </OpModal>
    );
};

export default VisitorListModal;
