import { FormInstance } from "antd";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateKiosk } from "store/slices/kiosksSlice";
import { fetchLocations } from "store/slices/locationsSlice";
import { AppDispatch, RootState } from "store/store";
import { UpdateKioskPayload } from "types/kioskTypes";
import { hasPermission } from "utils/utils";

interface KioskContentProps {
    kioskForm: FormInstance;
    optionsForm: FormInstance;
    setDefaultLocationId: (locationId: number) => void;
}

const KioskContent: React.FC<KioskContentProps> = ({ kioskForm, optionsForm, setDefaultLocationId }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedKiosk = useSelector((state: RootState) => state.kiosks.selectedKiosk);
    const locations = useSelector((state: RootState) => state.locations.locations);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasKioskRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    useEffect(() => {
        if (orgId) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [dispatch, orgId]);

    const handleSubmit = async (args: IOnSubmitArgs<UpdateKioskPayload>) => {
        const { values } = args;
        const payload: Partial<UpdateKioskPayload> = {
            name: values.name,
            siteId: values.siteId
        };
        if (selectedKiosk) {
            await dispatch(updateKiosk({ orgId, kioskId: selectedKiosk.id, kiosk: payload }));
        }
    };

    const locationOptions = locations.data.map(location => ({
        label: location.name,
        value: location.id
    }));

    // Check if selectedKiosk's siteId is in the locationOptions
    const validSiteId = locationOptions.some(option => option.value === selectedKiosk?.site.id) ? selectedKiosk?.site.id : undefined;

    const statusStyle = {
        backgroundColor: selectedKiosk?.status.id === 1 ? "#6D988A" : undefined,
        color: selectedKiosk?.status.id === 1 ? "#FFFFFF" : undefined,
    };

    return (
        <OpForm
            form={kioskForm}
            onSubmit={handleSubmit}
            hasError={false}
            defaultButtons={false}
            initialValues={{
                name: selectedKiosk?.label,
                status: selectedKiosk?.status.name,
                siteId: validSiteId // Use the validSiteId
            }}
            isReadOnly={!hasKioskWrite && hasKioskRead}
        >
            <OpForm.Input label="Name" name="name" rules={[{ required: true, message: 'Please enter the name' }]} />

            <OpForm.Input label="Status" name="status" disabled style={statusStyle} />

            <OpForm.Select
                label="Location"
                name="siteId"
                options={locationOptions}
                disabled={selectedKiosk?.status.id === 1}
                onChange={(option) => {
                    setDefaultLocationId(Number(option));
                    // Get the current selected locations from the form
                    const currentValues = optionsForm.getFieldValue('locations') || [];

                    // Filter out the selected option from the current values
                    const validValues = currentValues.filter(
                        (value: any) => value !== Number(option) // Ensure the selected option is removed
                    );

                    // Update the form values with the filtered selections
                    optionsForm.setFieldsValue({ locations: validValues });

                }} />

        </OpForm>
    );
};

export default KioskContent;
