import { Form, FormInstance, List } from "antd";
import { OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { Visitor } from "types/visitTypes";
import warningIcon from "images/icons/warning.svg";
import { useState } from "react";
import { formatFullName } from "utils/utils";
import { RedFlag } from "types/redFlagTypes";
import { getStatusColor, isVisitorRedFlagged, profileIcon } from "utils/visitorsHelper";
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import VisitorListModal from "./VisitorListModal";
import RedFlagMatchModal from "components/pages/visitors/drawer/profile/RedFlagMatchModal";
import { setSelectedVisitor } from "store/slices/visitsSlice";
import { AppDispatch } from "store/store";
import { useDispatch } from "react-redux";
import { VisitorWorkflowFields } from "types/visitorWorkflowTypes";

interface VisitorListProps {
    form: FormInstance;
    signInWorkflowFields?: VisitorWorkflowFields;
    redFlags: RedFlag[];
    isInviting: boolean;
    newVisitorName?: {
        firstName: string;
        middleName: string;
        lastName: string;
    }
    setNewVisitorName?: (filter: { firstName: string; middleName: string; lastName: string } | undefined) => void;
}

const VisitorList: React.FC<VisitorListProps> = ({ form, signInWorkflowFields, redFlags, isInviting, newVisitorName, setNewVisitorName }) => {
    const dispatch: AppDispatch = useDispatch();
    const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(true);
    const [isRedFlagMatchModalOpen, setIsRedFlagMatchModalOpen] = useState(false);
    const [currentVisitor, setCurrentVisitor] = useState<Visitor | null>(null);

    const handleOpenModal = (visitor?: Visitor) => {
        setCurrentVisitor(visitor || null);
        setIsVisitorModalOpen(true);
    };

    const handleCreateEditVisitor = (visitor: Visitor) => {
        const currentVisitors = form.getFieldValue('visitors') || [];
        if (currentVisitor) {
            const updatedVisitors = currentVisitors.map((v: Visitor) => (v === currentVisitor ? visitor : v));
            form.setFieldsValue({ visitors: updatedVisitors });
        } else {
            form.setFieldsValue({ visitors: [...currentVisitors, visitor] });
        }
    };

    return (
        <>
            <Form.List name="visitors">
                {(fields, { add, remove }) => (
                    <List
                        locale={{ emptyText: ' ' }}
                        dataSource={fields}
                        renderItem={(field, index) => (
                            <List.Item
                                key={field.key}
                                actions={[
                                    <EditOutlined onClick={() => handleOpenModal(form.getFieldValue(['visitors', field.name]))} />,
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        isVisitorRedFlagged(form.getFieldValue(['visitors', field.name]), redFlags) ? (
                                            <div
                                                onClick={() => {
                                                    setIsRedFlagMatchModalOpen(true);
                                                    dispatch(setSelectedVisitor(form.getFieldValue(['visitors', field.name])));
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img src={warningIcon} alt="Warning" style={{ width: '24px', height: '24px' }} />
                                            </div>
                                        ) : (
                                            profileIcon({
                                                firstName: form.getFieldValue(['visitors', field.name, 'firstName']).trim(),
                                                lastName: form.getFieldValue(['visitors', field.name, 'lastName']).trim(),
                                                size: 24,
                                                color: isInviting ? getStatusColor('Pending') : getStatusColor("Signed In"),
                                                marginRight: 0,
                                            })
                                        )
                                    }
                                    title={formatFullName(
                                        form.getFieldValue(['visitors', field.name, 'firstName']),
                                        form.getFieldValue(['visitors', field.name, 'middleName']),
                                        form.getFieldValue(['visitors', field.name, 'lastName'])
                                    )}
                                    description={form.getFieldValue(['visitors', field.name, 'email'])}
                                />
                            </List.Item>
                        )}
                    />
                )}
            </Form.List>
            <OpForm.Button type="dashed" onClick={() => handleOpenModal()} block icon={<PlusOutlined />}>
                Add Visitor
            </OpForm.Button>

            <VisitorListModal
                open={isVisitorModalOpen}
                isInviting={isInviting}
                signInWorkflowFields={signInWorkflowFields}
                visitor={
                    currentVisitor ??
                    (newVisitorName
                        ? {
                            firstName: newVisitorName.firstName !== "NONE" ? newVisitorName.firstName : undefined,
                            middleName: newVisitorName.middleName !== "NONE" ? newVisitorName.middleName : undefined,
                            lastName: newVisitorName.lastName !== "NONE" ? newVisitorName.lastName : undefined,
                        }
                        : undefined)
                }
                onOk={handleCreateEditVisitor}
                onClose={() => {
                    setIsVisitorModalOpen(false);
                    if (setNewVisitorName) {
                        setNewVisitorName(undefined);
                    }
                }}
            />

            <RedFlagMatchModal visible={isRedFlagMatchModalOpen} onClose={() => setIsRedFlagMatchModalOpen(false)} />
        </>
    )
}

export default VisitorList;
