import React, { useEffect, useState } from 'react';
import { Layout, Input, Checkbox, Modal, Spin, Form, Button, Upload, message } from 'antd';
import type { UploadProps } from 'antd';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { OpDivider } from 'components/customAntd/DLS/OpDivider/OpDivider';
import { FileOutlined, UploadOutlined } from '@ant-design/icons';
import './Register.scss';

const { Content } = Layout;

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Register: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null); // Manage a single file
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [fieldsConfig, setFieldsConfig] = useState<any>(null);
    const [agreements, setAgreements] = useState<any[]>([]);
    const [initialValues, setInitialValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [agreementError, setAgreementError] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    console.log("fieldsConfig", fieldsConfig);
    useEffect(() => {
        const token = location.search.substring(1);

        if (token) {
            axios.post(`${apiUrl}/visitors/accessTokens/${token}/validate`)
                .then(response => {
                    const { isValid } = response.data.data;
                    if (isValid) {
                        axios.get(`${apiUrl}/visitors?token=${token}`)
                            .then(response => {
                                const visitorData = response.data.data[0];
                                const visitor = visitorData.visitors[0];
                                if (visitor.length === 0) {
                                    setIsValid(false);
                                } else {
                                    setIsValid(true);
                                }
                                setInitialValues({
                                    firstName: visitor.firstName,
                                    middleName: visitor.middleName !== "NONE" ? visitor.middleName : "",
                                    lastName: visitor.lastName,
                                    email: visitor.email,
                                    visitPhone: visitor.mobilePhone,
                                    visitCompany: visitorData.company,
                                    purpose: visitorData.purpose,
                                });

                                setIsLoading(false);
                            })
                            .catch(error => {
                                console.error('Error fetching visitor details:', error);
                                setIsLoading(false);
                            });

                        axios.get(`${apiUrl}/visitors/workflowInFields?token=${token}`)
                            .then(response => {
                                const fieldConfig = response.data.data[0];
                                setFieldsConfig(fieldConfig);
                            })
                            .catch(error => {
                                console.error('Error fetching workflow fields:', error);
                            });

                        axios.get(`${apiUrl}/visitors/agreements?token=${token}`)
                            .then(response => {
                                setAgreements(response.data.data);
                            })
                            .catch(error => {
                                console.error('Error fetching agreements:', error);
                            });
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error('Token validation error:', error);
                    setIsValid(false);
                    setIsLoading(false);
                });
        } else {
            setIsValid(false);
            setIsLoading(false);
        }
    }, [location.search]);

    const handleFileChange: UploadProps['onChange'] = (info) => {
        const selected = info.fileList[0];

        if (selected) {
            console.log("selected", selected);
            // Check file size (1MB = 1,048,576 bytes)
            if (selected.size! > 1048576) {
                message.error('File size exceeds 1MB. Please upload a smaller file.');
                return;
            }

            // Save the file and notify success
            setSelectedFile(selected.originFileObj!);
            message.success(`${selected.name} file selected successfully`);
        }
    };

    const onFinish = async () => {
        try {
            const values = await form.validateFields();

            const uncheckedAgreements = agreements.filter((_, index) => !values[`agreement_${index}`]);

            if (uncheckedAgreements.length > 0) {
                setAgreementError("Please agree to all the required agreements.");
                return;
            }

            setAgreementError(null);

            const token = location.search.substring(1);
            const requestBody = {
                firstName: values.firstName ? values.firstName : undefined,
                lastName: values.lastName ? values.lastName : undefined,
                middleName: values.middleName ? values.middleName : undefined,
                company: values.visitCompany ? values.visitCompany : undefined,
                email: values.email ? values.email : undefined,
                mobilePhone: values.visitPhone ? values.visitPhone : undefined
            };

            await axios.patch(`${apiUrl}/visitors?token=${token}`, requestBody);

            if (selectedFile) {
                const formData = new FormData();
                formData.append('photoUploadFile', selectedFile); // Add the file
                await axios.post(`${apiUrl}/visitors/photo?token=${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            // Update Agreements Synchronously 
            for (const [index, agreement] of agreements.entries()) {
                const hasAgreed = values[`agreement_${index}`] ? "1" : "0";

                await axios.patch(`${apiUrl}/visitors/agreements?token=${token}`, {
                    documentId: agreement.id.toString(),
                    hasAgreed: hasAgreed
                });
            }

            navigate('/register/completed', { replace: true, state: { token } });

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleAgreementClick = async (agreementId: number) => {
        const token = location.search.substring(1);
        try {
            const response = await axios.get(`${apiUrl}/visitors/documentcontent/${agreementId}?token=${token}`);
            const name = response.data.data[0].document.name;
            const content = response.data.data[0].content;
            setModalTitle(name);
            setModalContent(content);
            setIsModalVisible(true);
        } catch (error) {
            console.error('Error fetching agreement content:', error);
        }
    };

    const renderField = (name: string, label: string, required: boolean, disabled: boolean = false) => (
        <Form.Item
            label={label}
            name={name}
            rules={required ? [{ required: true, message: `Please input your ${label.toLowerCase()}.` }] : []}
        >
            <Input disabled={disabled} />
        </Form.Item>
    );

    return (
        <Layout className="register-layout">
            <Content className="register-content">
                <img src="/images/invisit_full.png" alt="Logo" className="logo" />
                {isLoading ? (
                    <div className="loading-container">
                        <Spin className="loading-message" />
                    </div>
                ) : isValid && fieldsConfig ? (
                    <Form
                        form={form}
                        name="exampleForm"
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={initialValues}
                    >

                        <div className="register-header">
                            <p>Pre-register for your visit for a quick onsite sign-in.</p>
                        </div>
                        <OpDivider />

                        {renderField("firstName", "First Name", true, true)}
                        {renderField("lastName", "Last Name", true, true)}

                        {fieldsConfig.middleName.included ? renderField("middleName", "Middle Name", fieldsConfig.middleName.required) : null}
                        {fieldsConfig.email.included ? renderField("email", "Email Address", fieldsConfig.email.required, true) : null}
                        {fieldsConfig.visitPhone.included ? renderField("visitPhone", "Phone", fieldsConfig.visitPhone.required) : null}
                        {fieldsConfig.visitCompany.included ? renderField("visitCompany", "Company", fieldsConfig.visitCompany.required) : null}

                        <Form.Item
                            label="Photo"
                            name="photo"
                            extra="File size cannot exceed 1MB. File format must be JPEG or PNG. Limit 1."
                            rules={[{ required: true, message: 'Photo is required.' }]}
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                onChange={handleFileChange}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>

                        {/* {fieldsConfig.photoUpload.included && (
                            <Form.Item
                                label="Photo"
                                name="photo"
                                extra="File size cannot exceed 1MB. File format must be JPEG or PNG. Limit 1."
                                rules={[{ required: fieldsConfig.photoUpload.required, message: 'Photo is required.' }]}
                            >
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    onChange={handleFileChange}
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        )} */}

                        {agreements.length > 0 && (
                            <>
                                <OpDivider />
                                <p>Below are agreements required for your visits. Please check each agreement to agree.</p>
                                {agreements.map((agreement, index) => (
                                    <Form.Item
                                        name={`agreement_${index}`}
                                        key={index}
                                        valuePropName="checked"
                                        className="custom-checkbox-item"
                                        validateTrigger="onSubmit"
                                    >
                                        <Checkbox className="custom-checkbox">
                                            {agreement.name}
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAgreementClick(agreement.id);
                                                }}
                                                className="agreement-link"
                                                style={{ cursor: 'pointer', color: '#1890ff', marginLeft: '8px' }}
                                            >
                                                <FileOutlined />
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                ))}
                                {agreementError && (
                                    <div className="agreement-error-message">
                                        {agreementError}
                                    </div>
                                )}
                            </>
                        )}
                        <OpDivider />

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <div className="message-box">
                        <span>Invalid or expired link.</span>
                    </div>
                )}
            </Content>

            <footer className="register-footer">
                &copy; 2024 Invisit, LLC
            </footer>

            <Modal
                title={modalTitle}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
            >
                <Input.TextArea
                    value={modalContent}
                    rows={20}
                    readOnly
                    style={{ resize: 'none' }}
                />
            </Modal>
        </Layout>
    );
};

export default Register;
