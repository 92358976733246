import { useEffect, FunctionComponent, useCallback, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, ConfigProvider, notification } from 'antd';
import { THEME } from './op-themes';
import AppHeader from './components/layout/appHeader/AppHeader';
import SideMenu from './components/layout/sideMenu/SideMenu';
import PageContent from './components/layout/pageContent/PageContent';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/register/Register';
import { RootState, AppDispatch } from './store/store';
import { clearAuthState } from './store/slices/authSlice';
import RegisterCompleted from 'components/auth/register/RegisterCompleted';
import SignUp from 'components/auth/signUp/SignUp';
import GuestPass from 'components/guestPass/GuestPass';
import ResetPassword from 'components/auth/resetPassword/ResetPassword';
import './scss/main.scss';

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const appVersion = process.env.REACT_APP_VERSION;

const App: FunctionComponent = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const auth = useSelector((state: RootState) => state.auth.auth);
    const authData = auth?.data && auth.data.length > 0 ? auth.data[0] : null;
    const { globalUser } = useSelector((state: RootState) => state.users);
    const { globalLocation } = useSelector((state: RootState) => state.locations);

    // Determine if the current route is non-themed
    const isNonThemedRoute = useMemo(
        () =>
            ['/resetPassword', '/register', '/register/completed', '/guestpass', '/signUp'].some((path) =>
                location.pathname.toLowerCase().startsWith(path.toLowerCase())
            ),
        [location.pathname]
    );

    const clearAuthDataFromState = useCallback(() => {
        dispatch(clearAuthState());
    }, [dispatch]);

    useEffect(() => {
        const handleThemeChange = () => {
            // Toggle 'dark' class only if it's not a non-themed route
            if (!isNonThemedRoute) {
                document.documentElement.classList.toggle('dark', isDarkMode);
            } else {
                document.documentElement.classList.remove('dark');
            }

            window.dispatchEvent(new Event('themechange')); // Dispatch theme change event
        };

        handleThemeChange();
    }, [isDarkMode, location.pathname, isNonThemedRoute]);

    useEffect(() => {
        const checkAppVersion = () => {
            if (isNonThemedRoute) {
                return; // Do nothing for non-themed routes
            }

            const storedVersion = localStorage.getItem('appVersion') || '';

            if (appVersion && storedVersion !== appVersion) {
                localStorage.setItem('appVersion', appVersion);
                clearAuthDataFromState();
                navigate('/login', { replace: true });
                notification.info({
                    message: 'Application Updated',
                    description: 'The application has been updated. Please log in again.',
                    placement: 'bottomRight',
                });
            }
        };

        checkAppVersion();
    }, [isNonThemedRoute, clearAuthDataFromState, navigate]);

    useEffect(() => {
        if (isNonThemedRoute) {
            return; // Do nothing for non-themed routes
        }
        const storedApiUrl = localStorage.getItem('apiUrl') || '';

        if (storedApiUrl !== apiUrl) {
            clearAuthDataFromState();
            localStorage.setItem('apiUrl', apiUrl!);
            navigate('/login', { replace: true });
            notification.info({
                message: 'Application Updated',
                description: 'The application configuration has changed. Please log in again.',
                placement: 'bottomRight',
            });
        }
    }, [isNonThemedRoute, clearAuthDataFromState, navigate]);


    return (
        <>
            {isNonThemedRoute ? (
                // Render non-themed routes without ConfigProvider
                <Routes>
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    <Route path="/resetPassword/:token" element={<ResetPassword />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/register/completed" element={<RegisterCompleted />} />
                    <Route path="/guestPass" element={<GuestPass />} />
                    <Route path="/guestPass/:token" element={<GuestPass />} />
                    <Route path="/signUp" element={<SignUp />} />
                </Routes>
            ) : (
                // Render themed routes with ConfigProvider
                <ConfigProvider theme={isDarkMode ? THEME.dark : THEME.light}>
                    <Routes>
                        <Route path="/login" element={<LogIn />} />
                        <Route
                            path="/*"
                            element={
                                authData && globalUser && globalLocation ? (
                                    <Layout className="op-app">
                                        <AppHeader />
                                        <Layout>
                                            <SideMenu />
                                            <PageContent />
                                        </Layout>
                                    </Layout>
                                ) : (
                                    <LogIn /> // If user is not authenticated, redirect to login
                                )
                            }
                        />
                    </Routes>
                </ConfigProvider>
            )}
        </>
    );
};

export default App;