import React, { useEffect, useState } from 'react';
import { OpPage } from 'components/customAntd/OpPage/OpPage';
import { IOpTableProps, OpTable } from 'components/customAntd/DLS/OpTable/OpTable';
import { clearKiosks, createKiosk, deleteKiosk, describeKiosk, fetchKiosks } from 'store/slices/kiosksSlice';
import { useDispatch, useSelector } from 'react-redux';
import { OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { AppDispatch, RootState } from 'store/store';
import { OpTableRawColumnType } from 'components/customAntd/DLS/OpTableCore/OpTableCore';
import { Form, Input, Modal, notification, Avatar } from 'antd';
import KiosksDrawer from './KiosksDrawer';
import { fetchLocations } from 'store/slices/locationsSlice';
import linkConnected from 'images/icons/link_connected.svg';
import { TABLE_HEIGHT } from 'constants/ui';
import { KIOSK_TOOLTIP } from 'constants/tooltip';
import { CreateKioskPayload, Kiosk } from 'types/kioskTypes';
import { hasPermission } from 'utils/utils';
import { logUserActivity } from 'store/slices/userActivitySlice';
import { CONFIRM_DELETE_CONTENT, CONFIRM_DELETE_TITLE } from 'constants/messages';

const Kiosks: React.FC = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeKiosk, setActiveKiosk] = useState<Kiosk[]>([]);
    const [createForm] = OpForm.useForm();

    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { kiosks, fetchKiosksLoading } = useSelector((state: RootState) => state.kiosks);
    const { locations } = useSelector((state: RootState) => state.locations);

    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    // Fetch visits and red flags on organizationId change
    useEffect(() => {
        if (orgId) {
            dispatch(fetchLocations({ orgId, status: 1 }));
        }
    }, [dispatch, orgId]);

    // Fetch kiosks on mount and when organizationId changes, clear kiosks on unmount
    useEffect(() => {
        if (orgId) {
            dispatch(fetchKiosks({ orgId }));
        }
        return () => {
            dispatch(clearKiosks());
        };
    }, [dispatch, orgId]);

    // Filter active kiosks from the fetched kiosks
    useEffect(() => {
        if (kiosks && kiosks.data) {
            const filteredKiosks = kiosks.data.filter(kiosk => kiosk.status.id === 0 || kiosk.status.id === 1);
            setActiveKiosk(filteredKiosks);
        }
    }, [kiosks]);

    // Handle deleting a kiosk
    const handleDeleteKiosk = async (record: Kiosk) => {
        try {
            await dispatch(deleteKiosk({ orgId, kioskId: record.id }));
            dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "60", details: record.label || '' } }))
            notification.success({
                message: 'Success',
                description: 'Kiosk deleted successfully.',
                placement: 'bottomRight',
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to delete kiosk.',
                placement: 'bottomRight',
            });
        }
    };

    const handleCreateKiosk = async (values: any) => {
        try {
            const payload: CreateKioskPayload = {
                name: values.label,
                siteId: Number(locations.data[0].id)
            };
            await dispatch(createKiosk({ orgId, kiosk: payload }));
            dispatch(logUserActivity({ orgId, userActivity: { userId: globalUserId!, activityId: "50", details: values.label } }))
            notification.success({
                message: 'Success',
                description: 'Kiosk created successfully.',
                placement: 'bottomRight',
            });
            setIsModalOpen(false);
            createForm.resetFields();
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to create kiosk.',
                placement: 'bottomRight',
            });
        }
    };

    // Column configuration for the table
    const columns: OpTableRawColumnType[] = [
        {
            dataIndex: 'label',
            label: 'Kiosk Name',
            filter: { type: 'input' },
            sorter: (a, b) => (a.label || '').localeCompare(b.label || ''), // Alphabetical sorting with null check
            render: (text, record) => (
                <span>
                    {record.status.id === 1 && (
                        <Avatar src={linkConnected} size={20} style={{ marginRight: 8 }} />
                    )}
                    {text}
                </span>
            ),
        },
        {
            dataIndex: 'site',
            label: 'Location',
            sorter: (a, b) => (a.site || '').localeCompare(b.site || ''), // Alphabetical sorting with null check
            render: (text, record) => (record.site?.status === 1 ? record.site.name : ''), // Render name only if status is 1
        }
    ];

    // Table properties
    const opTableProps: IOpTableProps = {
        dataSource: activeKiosk,
        columns: columns,
        rowActions: {
            onEditClick: (kiosk: Kiosk) => {
                dispatch(describeKiosk({ orgId, kioskId: kiosk.id }));
                setDrawerVisible(true);
            },
            onDeleteClick: hasKioskWrite ? handleDeleteKiosk : undefined,
            deleteModalTitle: () => CONFIRM_DELETE_TITLE("Kiosk"),
            deleteModalContent: ({ record }) => CONFIRM_DELETE_CONTENT(record.label),
        },
        testId: 'dashboard-table',
        height: TABLE_HEIGHT,
        allowGlobalSearch: true,
        allowAddition: hasKioskWrite ? {
            itemName: 'Kiosk',
            onClick: () => {
                setIsModalOpen(true);
            },
        } : false,
        writeAccess: hasKioskWrite,
        loading: fetchKiosksLoading,
        allowExport: true,
        allowShowHideColumns: true,
        gtm: 'dashboard-table-gtm',
    };

    return (
        <OpPage title="Kiosks" tooltip={KIOSK_TOOLTIP}>
            <div>
                <OpTable {...opTableProps} />
                {(drawerVisible) && (
                    <KiosksDrawer
                        open={drawerVisible}
                        onClose={() => {
                            setDrawerVisible(false);
                        }}
                    />
                )}
                {(isModalOpen) && (
                    <Modal
                        title="New Device Label"
                        open={isModalOpen}
                        onOk={createForm.submit}
                        onCancel={() => setIsModalOpen(false)}
                        okText="Confirm"
                        cancelText="Cancel"
                        centered
                    >
                        <Form form={createForm} onFinish={handleCreateKiosk}>
                            <Form.Item
                                label="Device Label"
                                name="label"
                                rules={[{ required: true, message: 'Please enter the device label' }]}
                            >
                                <Input placeholder="Device Label" />
                            </Form.Item>
                        </Form>
                    </Modal>
                )}
            </div>
        </OpPage>
    );
}

export default Kiosks;
