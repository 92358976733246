import React, { useState, useEffect, useCallback } from 'react';
import { Divider, notification } from 'antd';
import { IOnSubmitArgs, OpForm } from 'components/customAntd/DLS/OpForm/OpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { User } from 'types/userTypes';
import { getRequest, patchRequest, postRequest } from 'api/apiClient';
import { formatFullName } from 'utils/utils';
import { Visitor } from 'types/visitTypes';
import Title from 'antd/es/typography/Title';
import { OpModal } from 'components/customAntd/DLS/OpModal/OpModal';
import Loader from 'components/customAntd/Loader';
import { fetchVisits, fetchVisitsToday } from 'store/slices/visitsSlice';
import { SIGNED_IN_VISITOR_ID } from 'constants/userActivities';
import { FormFieldWorkflow, VisitData } from 'types/formFieldTypes';

const RequiredFieldsModal: React.FC<{
    open: boolean;
    requiredFields: string[];
    checkedVisitors: Visitor[];
    onClose: () => void;
    updateVisitStatus: () => void;
    updateVisitorStatus: (visitorId: number) => void;
}> = ({ open, requiredFields, checkedVisitors, onClose, updateVisitStatus, updateVisitorStatus }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalUserId = useSelector((state: RootState) => state.users.globalUser?.id);
    const { selectedVisit } = useSelector((state: RootState) => state.visits);

    const [hosts, setHosts] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [fieldsWithoutValue, setFieldsWithoutValue] = useState<FormFieldWorkflow[]>([]);

    const [form] = OpForm.useForm();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const hosts = await getRequest(`/orgs/${orgId}/users`);
                const filteredHosts = hosts.data.filter((user: User) => user.status === 1 && (user.hostExclude === 0 || user.hostExclude === null));
                setHosts(filteredHosts);

                const locationWorkflowResponse = await getRequest(`/orgs/${orgId}/sites/${selectedVisit?.site.id}/workflows`);
                const customFieldsWorkflow = await getRequest(`/orgs/${orgId}/visitorWorkflow/${locationWorkflowResponse.data[0]?.SignInWorkflowId}/fieldCustom`);
                const visitData = await getRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`);

                // Filter fields without values
                const fields = customFieldsWorkflow.data.filter((formField: FormFieldWorkflow) => {
                    const matchingVisitData = visitData.data.find(
                        (data: VisitData) => data.formField.id === formField.id && data.value !== null
                    );
                    return formField.required === 1 && !matchingVisitData; // Keep only required fields without values
                });
                setFieldsWithoutValue(fields);
            } catch (error) {
                console.log("Failed to fetch data.");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [orgId, selectedVisit?.id, selectedVisit?.site.id]);

    const handleOk = async () => {
        form
            .validateFields()
            .then(() => {
                form.submit();
                onClose();
            })
            .catch((errorInfo) => {
                notification.error({
                    message: 'Validation Error',
                    description: 'Please fill in all required fields.',
                    placement: 'bottomRight',
                });
            });
    };


    const renderFormFields = () => {
        return (
            <>
                {fieldsWithoutValue.map((formField, index) => {
                    if (formField.values) {
                        const options = formField.values.map((option) => ({
                            label: option.display || option.name,
                            value: option.value,
                        }));

                        return (
                            <OpForm.Select
                                key={formField.id}
                                label={formField.name}
                                name={formField.id}
                                options={options}
                                rules={[{ required: formField.required === 1 }]}
                                placeholder={formField.placeholder ? formField.placeholder : undefined}
                            />
                        );
                    } else {
                        return (
                            <OpForm.Input
                                key={formField.id}
                                label={formField.name}
                                name={formField.id}
                                rules={[{ required: formField.required === 1 }]}
                                placeholder={formField.placeholder ? formField.placeholder : undefined}
                            />
                        );
                    }
                })}
            </>
        );
    };

    const handleSubmit = useCallback(async ({ values }: IOnSubmitArgs<any>) => {
        // Construct visit payload
        const visitPayload: Record<string, any> = {};
        if (values.company) visitPayload.company = values.company;
        if (values.hostId) visitPayload.hostId = values.hostId;
        if (values.visitPurpose) visitPayload.purpose = values.visitPurpose;

        console.log("values", values);
        // Submit visit-level payload
        await patchRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}`, visitPayload);

        // Group fields by visitor and submit each visitor's payload
        const visitorPayloads: Record<string, Record<string, any>> = {};

        Object.entries(values).forEach(([key, value]) => {
            if (key.startsWith("visitor_") && value) {
                // Extract visitor index and field name (e.g., "visitor_0_email")
                const [, index, field] = key.split("_");
                if (!visitorPayloads[index]) visitorPayloads[index] = {};
                visitorPayloads[index][field] = value;
            }
        });

        // Submit payloads for each visitor
        const visitorRequests = Object.entries(visitorPayloads).map(
            async ([index, payload]) => {
                await patchRequest(
                    `/orgs/${orgId}/visitor/${selectedVisit?.id}/visitors/${index}`,
                    payload
                );
            }
        );

        for (const key in values) {
            const field = values[key];

            // Check if the field is a custom field (custom fields have numeric keys)
            const isCustomField = !isNaN(Number(key));
            if (isCustomField) {
                // Add field if enabled
                try {
                    await postRequest(`/orgs/${orgId}/visitor/${selectedVisit?.id}/visitorData`, {
                        fieldId: key,
                        value: field,
                    });
                    console.log(`Added custom field ${key}.`);
                } catch (error) {
                    console.log(`Failed to add custom field ${key}:`, error);
                }
            }
        }

        await Promise.all(visitorRequests); // Ensure all requests complete

        // update status of visit and visitors
        for (const visitor of checkedVisitors) {
            if (visitor.id) {
                await updateVisitorStatus(visitor.id);
            }
        }
        await updateVisitStatus();

        // log activity
        const fullNameList = checkedVisitors?.map((visitor: Visitor) => formatFullName(visitor.firstName, visitor.middleName, visitor.lastName,)).join(', ');
        await postRequest(`/orgs/${orgId}/userActivity`, {
            userId: globalUserId,
            activityId: SIGNED_IN_VISITOR_ID,
            details: fullNameList
        });
        await dispatch(fetchVisits({ orgId }));
        await dispatch(fetchVisitsToday({ orgId }));

        form.resetFields();

        console.log("Visitor updates completed.");
    }, [orgId, selectedVisit, checkedVisitors, dispatch, form, globalUserId, updateVisitStatus, updateVisitorStatus]);

    const shouldShowVisitInformationTitle =
        (requiredFields.includes('visitCompany') && !selectedVisit?.company) ||
        ((requiredFields.includes('host') || hosts.length > 0) && !selectedVisit?.host) ||
        (requiredFields.includes('visitPurpose') && !selectedVisit?.purpose);

    return (
        <OpModal
            open={open}
            title="Required Fields"
            onOk={handleOk}
            onCancel={onClose}
            centered
        >
            {loading ? (
                <Loader />
            ) : (
                <OpForm
                    form={form}
                    onSubmit={handleSubmit}
                    defaultButtons={false}
                    hasError={false}
                    style={{ maxHeight: '70vh', overflowY: 'auto', padding: '8px' }}
                >
                    {(shouldShowVisitInformationTitle) && (
                        <Title level={5} style={{ marginBottom: 16 }}>
                            Visit Information
                        </Title>
                    )}
                    {requiredFields.includes('visitCompany') && !selectedVisit?.company && (
                        <OpForm.Input
                            label="Company"
                            name="company"
                            rules={[{ required: true, message: 'Please enter company.' }]}
                        />
                    )}
                    {(requiredFields.includes('host') && hosts.length > 0) && selectedVisit?.host.userId === null && (
                        <OpForm.Select
                            label="Host" name="hostId"
                            rules={[{ required: true, message: 'Please select a host.' }]}
                            showSearch
                            placeholder={hosts.length === 0 ? "No hosts are available" : "Select Host"}
                            disabled={hosts.length === 0}
                            options={hosts.map(host => ({
                                label: host.identity?.firstName || host.identity?.lastName
                                    ? formatFullName(host.identity.firstName, host.identity.middleName, host.identity.lastName)
                                    : host.identity?.email,
                                value: host.id
                            }))}
                        />
                    )}
                    {requiredFields.includes('visitPurpose') && !selectedVisit?.purpose && (
                        <OpForm.TextAreaInput
                            label="Purpose"
                            name="visitPurpose"
                            rules={[{ required: true, message: 'Please enter purpose.' }]}
                            rows={4}
                        />
                    )}
                    {checkedVisitors.some((visitor) =>
                        (requiredFields.includes('middleName') && !visitor.middleName) ||
                        (requiredFields.includes('email') && !visitor.email) ||
                        (requiredFields.includes('visitPhone') && !visitor.mobilePhone)
                    ) && checkedVisitors.map((visitor, index) => (
                        <div key={visitor.id} style={{ marginBottom: 24 }}>
                            {shouldShowVisitInformationTitle || index !== 0 ? <Divider /> : null}
                            <Title level={5} style={{ marginBottom: 16 }}>
                                {formatFullName(visitor.firstName, visitor.middleName, visitor.lastName)}
                            </Title>
                            {requiredFields.includes('middleName') && !visitor.middleName && (
                                <OpForm.Input
                                    label={`Middle Name`}
                                    name={`visitor_${visitor.id}_middleName`}
                                    rules={[{ required: true, message: 'Please enter middle name.' }]}
                                />
                            )}
                            {requiredFields.includes('email') && !visitor.email && (
                                <OpForm.Input
                                    label={`Email`}
                                    name={`visitor_${visitor.id}_email`}
                                    rules={[
                                        { required: true, message: 'Please enter email.' },
                                        { type: 'email', message: 'Please enter a valid email address' }
                                    ]}
                                />
                            )}
                            {requiredFields.includes('visitPhone') && !visitor.mobilePhone && (
                                <OpForm.Input
                                    label={`Phone`}
                                    name={`visitor_${visitor.id}_mobilePhone`}
                                    rules={[{ required: true, message: 'Please enter phone number.' }]}
                                />
                            )}
                        </div>
                    ))}
                    {(fieldsWithoutValue.length > 0) && (
                        <>
                            <Divider />
                            <Title level={5} style={{ marginBottom: 16 }}>
                                Custom Fields
                            </Title>
                        </>
                    )}
                    {renderFormFields()}
                </OpForm>
            )}
        </OpModal>
    );
};

export default RequiredFieldsModal;
