import React, { useEffect, useState } from "react";
import { List, FormInstance } from "antd";
import { IOnSubmitArgs, OpForm } from "components/customAntd/DLS/OpForm/OpForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { updateAppearanceConfig } from "store/slices/kiosksSlice";
import { UpdateAppearanceConfigPayload } from "types/kioskTypes";
import { hasPermission } from "utils/utils";
import { OpModal } from "components/customAntd/DLS/OpModal/OpModal";
import { patchRequest } from "api/apiClient";
import { Location } from "types/locationTypes";

interface OptionsContentProps {
    optionsForm: FormInstance;
    defaultLocationId: number | undefined;
    locations: Location[];
    kioskLocations: Location[];
    isMultiLocationsOn: boolean;
    isMultiLocationsOnInitially: boolean;
    setIsMultiLocationsOn: (check: boolean) => void;
    setIsFieldsTouched: (check: boolean) => void;
}

const OptionsContent: React.FC<OptionsContentProps> = ({ optionsForm, defaultLocationId, locations, kioskLocations, isMultiLocationsOn, isMultiLocationsOnInitially, setIsMultiLocationsOn, setIsFieldsTouched }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const selectedKiosk = useSelector((state: RootState) => state.kiosks.selectedKiosk);
    const tokenScopeList = useSelector((state: RootState) => state.auth.auth.data[0]?.tokenScopeList || []);
    const subscriptions = useSelector((state: RootState) => state.subscriptions.subscriptions);

    const hasKioskRead = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:r');
    const hasKioskWrite = hasPermission(tokenScopeList, orgId, 'o', 'kiosk:w');

    const [isScanIdCameraDisabled, setScanIdCameraDisabled] = useState(true);
    const [featureUnavailableModalOpen, setFeatureUnavailableModalOpen] = useState(false);

    // Define initial values as a constant
    const initialFormValues = {
        allowSignOut: selectedKiosk?.appearance[0]?.allowSignOut || false,
        scanQR: selectedKiosk?.appearance[0]?.scanQR || false,
        scanID: selectedKiosk?.appearance[0]?.scanID || false,
        scanIdCamera: selectedKiosk?.appearance[0]?.scanIdCamera || false,
        badgePrinter: selectedKiosk?.appearance[0]?.badgePrinter || false,
        allowMultiLocation: isMultiLocationsOnInitially || false,
        locations: kioskLocations.map((location) => location.id),
    };

    useEffect(() => {
        const initialScanIdState = selectedKiosk?.appearance[0].scanID;
        setScanIdCameraDisabled(!initialScanIdState);
        if (!initialScanIdState) {
            optionsForm.setFieldsValue({ scanIdCamera: false });
        }
    }, [selectedKiosk, optionsForm]);

    const handleSubmit = async (args: IOnSubmitArgs) => {
        if (!orgId) { return; }

        const { values } = args;
        const payload: Partial<UpdateAppearanceConfigPayload> = {
            allowSignOut: values.allowSignOut ? 1 : 0,
            scanQR: values.scanQR ? 1 : 0,
            scanID: values.scanID ? 1 : 0,
            scanIdCamera: values.scanIdCamera ? 1 : 0,
            badgePrinter: values.badgePrinter ? 1 : 0
        };
        if (selectedKiosk) {
            await dispatch(updateAppearanceConfig({ orgId, kioskId: selectedKiosk.id, appearanceConfig: payload }));
            if (isMultiLocationsOn && values.locations.length > 0) {
                await patchRequest(`/orgs/${orgId}/kiosk/${selectedKiosk.id}/sites`, { siteId: values.locations });
            } else {
                await patchRequest(`/orgs/${orgId}/kiosk/${selectedKiosk.id}/sites`, { siteId: [] });
            }
        }
    };

    const handleScanIdChange = (checked: boolean) => {
        setScanIdCameraDisabled(!checked);
        if (!checked) {
            optionsForm.setFieldsValue({ scanIdCamera: false });
        }
    };

    const handleBadgePrinterChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential") {
            // Prevent the change and show the modal
            setFeatureUnavailableModalOpen(true);
            optionsForm.setFieldsValue({ badgePrinter: selectedKiosk?.appearance[0].badgePrinter }); // Reset the value in the form
        }
    };

    const handleScanQRChange = (checked: boolean) => {
        if (subscriptions?.data[0]?.package?.code === "package-essential") {
            setFeatureUnavailableModalOpen(true);
            optionsForm.setFieldsValue({ scanQR: selectedKiosk?.appearance[0].scanQR }); // Reset the value in the form
        }
    };


    const handleToggleAllowMultiLocation = (checked: boolean) => {
        // Update the form value directly
        optionsForm.setFieldsValue({ allowMultiLocation: checked });

        // Trigger any additional state updates
        setIsMultiLocationsOn(checked);
        setIsFieldsTouched(true);
    };

    return (
        <>
            <OpForm
                form={optionsForm}
                onSubmit={handleSubmit}
                hasError={false}
                defaultButtons={false}
                initialValues={initialFormValues}
                isReadOnly={!hasKioskWrite && hasKioskRead}
            >
                <List
                    header={<div>KIOSK OPTIONS</div>}
                    dataSource={[
                        { name: 'allowSignOut', label: 'Allow Sign Out', valuePropName: 'checked' },
                        { name: 'scanQR', label: 'Pre-Registration QR code', valuePropName: 'checked' },
                        { name: 'scanID', label: 'Scan ID To Identify', valuePropName: 'checked' },
                        { name: 'scanIdCamera', label: 'Use Camera', valuePropName: 'checked', dependent: true },
                        { name: 'badgePrinter', label: 'Badge Printer', valuePropName: 'checked' },
                    ]}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Switch
                                    name={item.name}
                                    style={{ marginBottom: 0 }}
                                    disabled={item.dependent && (isScanIdCameraDisabled || (!hasKioskWrite && hasKioskRead))}
                                    onChange={
                                        item.name === 'scanID'
                                            ? handleScanIdChange
                                            : item.name === 'badgePrinter'
                                                ? handleBadgePrinterChange
                                                : item.name === 'scanQR'
                                                    ? handleScanQRChange
                                                    : undefined
                                    } />
                                <span style={{ marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )}
                />

                <List
                    header={<div>MULTI-LOCATION</div>}
                    dataSource={[
                        { name: 'allowMultiLocation', label: 'Allow Multiple Locations', valuePropName: 'checked' },
                    ]}
                    bordered
                    renderItem={item => (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Item name={item.name} valuePropName="checked" style={{ marginBottom: 0 }}>
                                    <OpForm.Switch
                                        onChange={handleToggleAllowMultiLocation}
                                    />
                                </OpForm.Item>
                                <span style={{ flex: 1, marginLeft: 8 }}>{item.label}</span>
                            </div>
                        </List.Item>
                    )}
                    style={{ marginTop: '24px' }}
                >
                    {isMultiLocationsOn && (
                        <List.Item>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <OpForm.Item name="locations" style={{ flex: 1, marginBottom: 0 }}>
                                    <OpForm.Select
                                        mode="multiple"
                                        placeholder="Select Locations"
                                        options={locations
                                            .filter(location => location.id !== defaultLocationId) // Exclude defaultLocationId
                                            .map(location => ({
                                                label: location.name,
                                                value: location.id,
                                            }))}
                                    />
                                </OpForm.Item>
                            </div>
                        </List.Item>
                    )}
                </List>
            </OpForm >

            <OpModal
                open={featureUnavailableModalOpen}
                onCancel={() => setFeatureUnavailableModalOpen(false)}
                onOk={() => setFeatureUnavailableModalOpen(false)}
                title="Feature Not Included"
            >
                <p>Your account does not currently include this feature. Please contact your administrator.</p>
            </OpModal>
        </>
    );
};

export default OptionsContent;
