import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OpCollapse } from 'components/customAntd/DLS/OpCollapse/OpCollapse';
import dayjs from 'dayjs';
import { Select, DatePicker, Button, notification } from 'antd';
import { describeLocation, describeLocationWorkflow } from 'store/slices/locationsSlice';
import { RootState, AppDispatch } from 'store/store';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { getRequest } from 'api/apiClient';
import { DATE_TIME_FORMAT } from 'constants/dates';
import { Location } from 'types/locationTypes';

interface DateRangeLocationFilterProps {
    onDateRangeLocationFilter: (locationId: number, startDate: string, endDate: string) => void;
    initialStartDate: string;
    initialEndDate: string;
}

const { RangePicker } = DatePicker;

const DateRangeLocationFilter: React.FC<DateRangeLocationFilterProps> = ({ onDateRangeLocationFilter, initialStartDate, initialEndDate }) => {
    const dispatch: AppDispatch = useDispatch();
    const orgId = useSelector((state: RootState) => state.globalOrg.globalOrgId);
    const globalLocationId = useSelector((state: RootState) => state.locations.globalLocation?.id);

    const [locations, setLocations] = useState<Location[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
        dayjs(initialStartDate),
        dayjs(initialEndDate)
    ]);

    // Fetch locations
    useEffect(() => {
        const fetchLocations = async () => {
            setLoading(true);
            try {
                const locations = await getRequest(`/orgs/${orgId}/sites`, { status: 1 });
                setLocations(locations.data);
            } catch (error) {
                console.log("Failed to fetch locations.");
            } finally {
                setLoading(false);
            }
        };
        fetchLocations();
    }, [orgId]);

    const handleUpdate = () => {
        if (globalLocationId && dateRange && dateRange[0] && dateRange[1]) {
            const formattedStartDate = dateRange[0].startOf('day').format(DATE_TIME_FORMAT);
            const formattedEndDate = dateRange[1].endOf('day').format(DATE_TIME_FORMAT);
            onDateRangeLocationFilter(globalLocationId, formattedStartDate, formattedEndDate);
            notification.success({
                message: 'Success',
                description: 'The visitors have been filtered successfully.',
                placement: 'bottomRight'
            });
        }
    };

    const handleLocationChange = async (locationId: number) => {
        await dispatch(describeLocation({ orgId, locationId, global: true }));
        await dispatch(describeLocationWorkflow({ orgId, locationId, global: true }));
    };

    // Disabled 7 days from the selected date
    const disabled7DaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
        if (from) {
            return Math.abs(current.diff(from, 'days')) >= 7;
        }
        return false;
    };

    const rangePresets: RangePickerProps['presets'] = [
        { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
        { label: 'Tomorrow', value: [dayjs().add(1, 'day').startOf('day'), dayjs().add(1, 'day').endOf('day')] },
        { label: 'Yesterday', value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')] },
        { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
        { label: 'Next Week', value: [dayjs().add(1, 'week').startOf('week'), dayjs().add(1, 'week').endOf('week')] },
        { label: 'Last Week', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')] },
    ];

    return (
        <OpCollapse defaultActiveKey={['1']} items={[{
            key: '1',
            label: 'Filter Visitors',
            children: (
                <div style={{ display: 'flex', gap: '16px', alignItems: 'center', width: '100%' }}>
                    <Select
                        value={
                            loading
                                ? undefined // Show nothing if loading
                                : globalLocationId === -1 // Check for "All Locations"
                                    ? -1
                                    : locations.find(location => location.id === globalLocationId)?.id // Ensure the location id matches
                        }
                        style={{ width: 200 }}
                        options={
                            locations.length > 1
                                ? [{ label: "All Locations", value: -1 }, ...locations.map(location => ({ value: location.id, label: location.name }))]
                                : locations.map(location => ({ value: location.id, label: location.name }))
                        }
                        onChange={handleLocationChange}
                        loading={loading}
                        placeholder="Select Location"
                    />

                    <RangePicker
                        style={{ flex: 1 }}
                        value={dateRange}
                        onChange={(dates) => setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])}
                        presets={rangePresets}
                        disabledDate={disabled7DaysDate}
                    />
                    <Button type="primary" onClick={handleUpdate}>Update</Button>
                </div>
            )
        }]} />
    );
};

export default DateRangeLocationFilter;